import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { getAuthToken, getUserRole, request, setAuthToken, setUserName, setUserRole } from '../helpers/axios_helper';
import Login from './Login';
import AppRoutes from '../helpers/AppRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const AppContent: React.FC<{}> = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const showSuccessToast = () => {
        toast.success("Authentification réussie !");
    };

    const showErrorToast = () => {
        toast.error("Mauvais login ou mot de passe !");
    };

    // Restore authentication status on initial render
    // useEffect(() => {
    //     const token = getAuthToken(); // Get token from localStorage
    //     if (token) {
    //         setAuthToken(token);
    //         setIsAuthenticated(true);
    //     }
        
    // }, []);

    useEffect(() => {
        const token = getAuthToken(); // Get token from localStorage
        if (token) {
            setAuthToken(token);
            setIsAuthenticated(true);
        }
        

    }, []);


    const handleLogin = (e: React.FormEvent, username: string, password: string) => {
        e.preventDefault();
        request(
            "POST",
            "/api/v1/auth/authenticate",
            {
                login: username,
                password: password
            }
        )
            .then((response) => {
                showSuccessToast();
                setAuthToken(response.data.token);
                setUserName(response.data.login);
                setUserRole(response.data.roles[4])
                setIsAuthenticated(true);
                navigate("/menu");
            })
            .catch((error) => {
                showErrorToast(); // Update the isAuthenticated state to false if login fails
                setAuthToken(null);
                setIsAuthenticated(false);
            });
    };

    return (
        <>
            {/* If authenticated, render the protected routes */}
            {isAuthenticated ? (
                <>
                    <ToastContainer />
                    <AppRoutes isAuthenticated={isAuthenticated} />
                </>
            ) : (
                // If not authenticated, render the login page
                <Login onLogin={handleLogin} />
            )}
        </>
    );
};

export default AppContent;
