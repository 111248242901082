// Fichier : src/Tarification.tsx

import React, { useState } from 'react';
import TarificationSearchNumberStep from './TarificationSearchNumberStep';
import TarificationDisplayNumberStep from './TarificationDisplayNumberStep';
import TarificationEnterTariffStep from './TarificationEnterTariffStep';
import Header from '../Header/Header';

const Tarification: React.FC = () => {
    const [step, setStep] = useState<number>(1);
    const [searchResponse, setSearchResponse] = useState<any>(null);

    const handleValidationStep1 = (response: any) => {
        // Fonction pour passer à l'étape 2 avec la réponse de la recherche
        setSearchResponse(response);
        setStep(2);
    };

    const handleSearchAgainStep2 = () => {
        // Fonction pour revenir à l'étape 1 depuis l'étape 2
        setStep(1);
    };

    const handleChangeTariffStep2 = () => {
        // Fonction pour passer à l'étape 3
        setStep(3);
    };

    const handlePreviousStep3 = () => {
        // Fonction pour revenir à l'étape 2 depuis l'étape 3
        setStep(1);
    };

    return (
        <>
            <div className="header-content">
                <Header />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div>
                    {step === 1 && (
                        <TarificationSearchNumberStep
                            visible={true}
                            onValidation={handleValidationStep1}
                        />
                    )}
                    {step === 2 && (
                        <TarificationDisplayNumberStep
                            visible={true}
                            onSearchAgain={handleSearchAgainStep2}
                            onChangeTariff={handleChangeTariffStep2}
                            searchResponse={searchResponse} // Passe la réponse de la recherche à l'étape 2
                        />
                    )}
                    {step === 3 && (
                        <TarificationEnterTariffStep
                            visible={true}
                            responseFromSearch={searchResponse}
                            onPreviousStep={handlePreviousStep3}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Tarification;
