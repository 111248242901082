import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import GuardedRoute from './GuardedRoute';
import Menu from '../Menu/Menu';
import AppContent from '../Login/AppContent';
import Tarification from '../Tarification/Tarification';
import PubEtAccueil from '../Traitement_son/AcceuilEtPub';
import Utilisateurs from '../Utilisatuers/Utilisateurs';
import { getAuthToken } from './axios_helper';
import PubAleatoire from '../Traitement_son/PubAleatoire';
import MultipleNum from '../Service/ServiceGroupeNumeros';
import Gestionhotesse from '../gestionhotesse/Gestionhotesse';

interface AppRoutesProp {
    /**
     * True, if the user is authenticated, false otherwise.
     */
    isAuthenticated: boolean;
}

const MENU_ROUTE = '/menu';
const LOGIN_ROUTE = '/login';
const TARIF_ROUTE = '/tarification'; 
const GESTION_SON_ROUTE = '/gestionson';
const GESTION_UTILISATEURS = '/utilisateurs';
const GESTION_SON_PUB_ALEATOIRE = `/Pub_aleatoire/:Pbase/:Pnumero`;
const GROUPE_DE_NUMEROS = `/choix_multiple`;
const AppRoutes = (props: AppRoutesProp): JSX.Element => {
    const { isAuthenticated } = props;
    const token = getAuthToken ();
    useEffect(() => {
        const currentURL = window.location.pathname;
        const pathredirect = currentURL.endsWith('/') || currentURL.endsWith('/login');
        if(token && pathredirect ) {
            window.location.href = '/menu';
        }
    })

    return (
        <Routes>
            {/* Unguarded Routes */}
            {/* Login Route */}
            <Route path={LOGIN_ROUTE} element={<AppContent />} />

            {/* Authenticated Routes */}
            <Route
                element={
                    <GuardedRoute
                        isRouteAccessible={isAuthenticated}
                        redirectRoute={MENU_ROUTE}
                        // redirectRoute={LOGIN_ROUTE}
                    />
                }
            >
            <Route path={MENU_ROUTE} element={<Menu />} />
                <Route path={GESTION_SON_ROUTE} element={<PubEtAccueil />} />
                <Route path={TARIF_ROUTE} element={<Tarification />} />
                <Route path={GESTION_UTILISATEURS} element={<Utilisateurs />} />
            </Route>

            {/* Not found Route */}
            <Route path={LOGIN_ROUTE} element={<AppContent />} />
            <Route path={GESTION_SON_PUB_ALEATOIRE} element={<PubAleatoire />} />
            <Route path={GROUPE_DE_NUMEROS} element={<MultipleNum/>} />

            <Route path="*" element={<p>Page Not Found</p>} />
        </Routes>
    );
};

export default AppRoutes;
