// Fichier : src/TarificationDisplayNumberStep.tsx

import React, { useEffect, useState } from 'react';
import TitleIcon from './TitleIcon'; // Importe le composant TitreIcon
import Header from '../Header/Header';
import { getUserRole } from '../helpers/axios_helper';

interface SearchResponse {
    codeRetour: number;
    message: string;
    regroupement: string;
    numero: string;
    tarif: string;
    // Ajoutez d'autres propriétés de la réponse de recherche ici
}

const TarificationDisplayNumberStep: React.FC<{
    visible: boolean;
    onSearchAgain: () => void;
    onChangeTariff: () => void;
    searchResponse?: SearchResponse; // Le '?' indique que searchResponse est optionnel
    }> = ({ visible, onSearchAgain, onChangeTariff, searchResponse }) => {
    if (!visible) return null;

    const [checkrole, setCheckrole]= useState(false)


    const handleChangeTariff = () => {
        // Appel de la fonction pour passer à l'étape 3
        onChangeTariff();
    };

    useEffect(() => {
        const role = getUserRole();
        if (role === 'ROLE_ADMIN') {
          setCheckrole(true);
        }
      }, [])

    return (
        <>
            <div>
                <TitleIcon title="Étape 2 : Affichage détaillé du numéro" icon="chemin-vers-l-icone" />
                {searchResponse ? ( // Vérifie si searchResponse est défini avant d'accéder à ses propriétés
                    // Utilisation d'une table pour afficher les détails
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th>Numéro</th>
                                <th>Tarif</th>

                            </tr>
                            <tr>
                                <td>{searchResponse.numero}</td>
                                <td>{searchResponse.tarif}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    // Affiche un message si searchResponse est indéfini
                    <p>Aucun résultat de recherche trouvé.</p>
                )}
                <div className="center">
                {/* Bouton "Chercher un autre numéro" */}
                {checkrole && (<button className="btn btn-secondary btn-sm btn-tarif" onClick={onSearchAgain}>Changer de numéro</button>)}
                {/* Bouton "Changer tarif" qui fait passer à l'étape suivante */}
                {checkrole && (<button className="btn btn-secondary btn-sm btn-tarif" onClick={handleChangeTariff}>Changer de tarif</button>)}
                {/* * Raison : L'étape 2 affiche les détails, donc il n'est pas nécessaire d'avoir des boutons de navigation */}
                </div>
            </div>
        </>
    );
};

export default TarificationDisplayNumberStep;
