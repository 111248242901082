import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuthToken, getUserName,setUserName, setUserRole } from '../helpers/axios_helper';


const Header = () => {
    const navigate = useNavigate();


    const handleLogoutClick = () => {
        navigate("/login");
        localStorage.clear(); // Clear the token using setAuthToken
    };
    const gotomenu=()=>{
        navigate("/menu")
    };
     const username=getUserName()

    return (
        <div className="header">
            <div className="row">
                <div className='col-md-6 text-left'>
                    <a onClick={gotomenu}>
                     <img id='btn afone-logo' style={{width:'150px', height:'40px'}} className="icone-afone-header" src="/images/afone_logo.png" alt=""/>
                     <span style={{color:'#eee'}}>&nbsp; V 2.0.1</span> 
                    </a>
                </div>

                <div className='col-md-6 menu_option'>
                    <ul>
                        <li className="figure" onClick={handleLogoutClick}>
                            <img className="Sirv image-hover" src="/images/icone_logout_hover.svg" alt=""/>
                        </li>
                        <li className='btn-menu'>
                            <span style={{color:'#ffff', fontSize:'16px'}}>Bienvenue </span>
                            <span style={{color:'#C11E65', fontSize:'20px'}}>{username}</span> 
                        </li>
                        <li className=''>
                            <img className='menu-image'  src="/images/dots.svg" alt="" onClick={gotomenu}/>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>        
    );
};

export default Header;

