import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import { useEffect, useState } from 'react';
import { getUserRole } from '../helpers/axios_helper';
import { exit } from 'process';



const Menu = () => {
    const [checkadmin, setadminvalue] = useState('');
    const [isAdmin, setisAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Utilisez getUserRole à l'intérieur de useEffect
        const adminvalue = getUserRole();
        // Utilisez setisAdmin pour mettre à jour l'état
        setisAdmin(adminvalue === 'ROLE_ADMIN');
    }, []);


    return (
        <div className='menu-content'>
            <div className="header-content">
                <Header />
            </div>
            <div className="main-menu center">
                <h3 className="text-center">Pour commencer</h3>
                <p className="text-center">Choisissez une application parmi la liste ci-dessous</p>
            </div>

            <div className="backgroundImg">
                <div className="cardMenu center">
                    {isAdmin ? (
                        <>
                            <div className="item item--1 " onClick={() => navigate("/tarification")}>
                                <div className="content-image">
                                    <img src="/images/icone_tarification.svg" />
                                </div>
                            </div>

                            <div className="item item--1 " id="gestioson" onClick={() => navigate("/gestionson")}>
                                <div className="content-image">
                                    <img src="/images/icon_son_et_pub.svg" />
                                </div>
                            </div>

                            <div className="item item--1 " onClick={() => navigate("/utilisateurs")} >
                                <div className="content-image">
                                    <img src="/images/icone_utilisateur.svg" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="item item--1 " onClick={() => navigate("/tarification")}>
                                <div className="content-image">
                                    <img src="/images/icone_tarification.svg" />
                                </div>
                            </div>

                            <div className="item item--1 " id="gestioson" onClick={() => navigate("/gestionson")}>
                                <div className="content-image">
                                    <img src="/images/icon_son_et_pub.svg" />
                                </div>
                            </div>

                        </>
                    )}

                </div>
            </div>
        </div>
    );
}

export default Menu
