
import { BrowserRouter, Route} from 'react-router-dom';
import AppContent from './components/Login/AppContent';
import PubEtAccueil from './components/Traitement_son/AcceuilEtPub';
import { useEffect } from 'react';
import { getAuthToken, setAuthToken, setUserName} from './components/helpers/axios_helper';

const App = () => {
    

    return (
        <>
  
            <BrowserRouter>
                <AppContent  />
            </BrowserRouter>
        </>
    );
};

export default App;