import * as React from 'react';


interface LoginProps {
    onLogin: (e: React.FormEvent, login: string, password: string) => void;
}

interface LoginState {
    [key: string]: string;
}

export default class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            login: "",
            password: ""
        };
    }

    onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    onSubmitLogin = (e: React.FormEvent) => {
        e.preventDefault();
        const { login, password } = this.state;
        this.props.onLogin(e, login, password);
    };



render() {
    

    return (
        <div className="content">
            
            <div className="backgroundImg">
                <div className="login-body">
                    <img className="icone-afone" src="/images/afone_logo.png" alt="" />

                        <div className="login-title"><h1>S’identifier</h1><p>Veuillez entrer votre identifiant et votre mot de passe</p></div>
                        <div className="field">
                        <form onSubmit={this.onSubmitLogin}>
                                <label className="label">Votre identifiant</label>
                                <div className="control">
                                    <input
                                        type="login"
                                        className="login-input"
                                        id="login"
                                        name="login"
                                        placeholder="Login"
                                        onChange={this.onChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Password</label>
                                    <div className="control">
                                        <input
                                            className="login-input"
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="password "
                                            onChange={this.onChangeHandler}
                                            required
                                        />
                                    </div>
                                    <a href="#">Identifiant ou mot de passe oublié ?</a>
                                    <button
                                        type="submit"
                                        className="button is-block is-info is-fullwidth"
                                    >
                                        Login
                                    </button>
                                    <div className="logoAfoneGroup align-middle">
                                        <div className="afone">
                                            <img id='ecp' src="/images/ECP.svg" />
                                        </div>
                                        <div className="afone">
                                            <img id='tcv' src="/images/TCV.svg" />
                                        </div>
                                        <div className="afone">
                                            <img id='neo' src="/images/NEO.svg" />
                                        </div>
                                    </div>

                                </div>
                            
                            </form>
                        </div>
                    </div>

                </div>
            <div className="footer">
                <div className="pinkBorder"></div>
                <div className="purpleBorder"></div>
            </div>
        </div>
    );
};
}

