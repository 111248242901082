// Fichier : src/TitleIcon.tsx

import React from 'react';
import Header from '../Header/Header';

interface TitreIconProps {
  title: string;
  icon: string;
}

const TitleIcon: React.FC<TitreIconProps> = ({ title, icon }) => {
  return (
    <>
      
      <div className='tarification-numero'>
        <center>
          <img src="/images/icone_tarification.svg" alt="Icon" /><br /><br />
          <h3 className='text-center'>{title}</h3>
          <p>Veuillez entre un numéro pour afficher  ses informations</p>
        </center>
      </div>
    </>

  );
};
export default TitleIcon;
