// Fichier : src/TarificationSearchNumberStep.tsx

import React, { useState } from 'react';
import TitleIcon from './TitleIcon'; // Importe le composant TitreIcon
import { getAuthToken, request } from '../helpers/axios_helper';
import Header from '../Header/Header';

const TarificationSearchNumberStep: React.FC<{ visible: boolean; onValidation: (response: any) => void; }> = ({ visible, onValidation }) => {
    const [searchValue, setSearchValue] = useState<string>('');

    if (!visible) return null;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleValidation = async () => {
        try {
            // Effectue la recherche en utilisant l'endpoint approprié avec Axios
            const token = getAuthToken(); // Récupérer le token du local storage
            const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
            // Préparer les headers avec le token (s'il existe)
            const response = await request('get', `/traitementFichier/getMgit?numero=${searchValue}`, headers);
            // Appelle la fonction onValidation avec la réponse de la recherche pour passer à l'étape suivante (étape 2)
            onValidation(response.data);
        } catch (error) {
            // Gère les erreurs de la recherche ici
            console.error('Erreur lors de la recherche du numéro :', error);
        }
    };

    return (
        <>
            <div>
                <TitleIcon title="Étape 1 : Recherche d'un numéro" icon="chemin-vers-l-icone" />
                <div className="input-group mb-3 tarification">
                    <input
                        type="text"
                        className="form-control"
                        id='input-form'
                        placeholder="Saisir un numéro"
                        value={searchValue}
                        onChange={handleInputChange}

                    />
                    <a className="btn btn-outline-secondary svg" type="button" onClick={handleValidation}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </a>
                </div>
            </div>
        </>
    );
};

export default TarificationSearchNumberStep;
