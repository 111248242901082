// Fichier : src/TarificationEnterTariffStep.tsx

import React, { useState } from 'react';
import TitleIcon from './TitleIcon'; // Importe le composant TitleIcon
import { getAuthToken } from '../helpers/axios_helper'; // Importe la fonction getAuthToken depuis axios_helper
import axios from 'axios';
import Header from '../Header/Header';

const TarificationEnterTariffStep: React.FC<{
  visible: boolean;
  responseFromSearch: any; // La réponse de la recherche depuis l'étape 1
  onPreviousStep: () => void;
}> = ({ visible, responseFromSearch, onPreviousStep }) => {
  if (!visible) return null;

  // État local pour gérer les saisies de l'utilisateur
  const [tarifType, setTarifType] = useState<string>('par_appel');
  const [tarifMontant, setTarifMontant] = useState<string>('');

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTarifType(event.target.value);
  };

  const handleMontantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTarifMontant(event.target.value);
  };


  const handleValidation = async () => {
    // Crée un objet JSON avec les valeurs récupérées
    const requestBody = {
      numero: responseFromSearch.numero,
      regroupement: tarifType === 'par_appel' ? 'APPEL' : 'DUREE',
      prix: tarifMontant, // Convertit en nombre et formate avec deux décimales
    };

    // Récupérer le token du local storage
    const token = getAuthToken();

    try {
      // Effectue la requête POST avec Axios
      await axios.post('/traitementFichier/updateMgit', requestBody, {
        // Inclure le token d'authentification dans les en-têtes de la requête
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      // Passer à l'étape précédente (étape 2) en appelant la fonction onPreviousStep
      onPreviousStep();
    } catch (error) {
      // Gère les erreurs de la requête ici
      console.error('Erreur lors de la mise à jour du tarif :', error);
    }
  };


  return (
    <>
      <div>
        <TitleIcon title="Étape 3 : Saisie du tarif" icon="chemin-vers-l-icone" />
        {/* Utilisation d'un formulaire pour la saisie du tarif */}
        <form>
          <div className="form-group">
            <label>Type de tarif :</label>
            <select className="form-control" value={tarifType} onChange={handleTypeChange}>
              <option value="par_appel">Par appel</option>
              <option value="par_minute">Par minute</option>
            </select><br />
          </div>

          <div className="form-group">
            {tarifType === 'par_appel' ? (
              <div className="form-group">
                <select className="form-select" value={tarifMontant} onChange={handleMontantChange}>
                  <optgroup label="Par appel">
                    <option value="060">0,6€</option>
                    <option value="065">0,65€</option>
                    <option value="079">0,79€</option>
                    <option value="080">0,8€</option>
                    <option value="090">0,9€</option>
                    <option value="099">0,99€</option>
                    <option value="100">1€</option>
                    <option value="150">1,5€</option>
                    <option value="199">1,99€</option>
                    <option value="200">2€</option>
                    <option value="250">2,5€</option>
                    <option value="299">2,99€</option>
                    <option value="300">3€</option>
                  </optgroup>
                </select>

              </div>
            ) : (
              <div className="form-group">
                <select className="form-select" value={tarifMontant} onChange={handleMontantChange}>
                  <optgroup label="Par minute">
                    <option value="015">0,15€</option>
                    <option value="018">0,18€</option>
                    <option value="020">0,20€</option>
                    <option value="025">0,25€</option>
                    <option value="030">0,30€</option>
                    <option value="035">0,35€</option>
                    <option value="040">0,40€</option>
                    <option value="045">0,45€</option>
                    <option value="050">0,50€</option>
                    <option value="060">0,60€</option>
                    <option value="070">0,70€</option>
                    <option value="080">0,80€</option>
                  </optgroup>
                </select>
              </div>
            )}
          </div><br />

          {/* Bouton "Valider" qui effectue la requête POST */}
          {tarifMontant !== '' && (
            <button type="button" className="btn btn-secondary btn-sm btn-tarif center" onClick={handleValidation}>
              Valider
            </button>
          )}
        </form>
        {/* Bouton "Précédent" qui fait revenir à l'étape précédente (étape 2) */}
        <button className="btn btn-secondary btn-sm btn-tarif center" onClick={onPreviousStep}>
          Arrière
        </button>

      </div>
    </>
  );
};

export default TarificationEnterTariffStep;