import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Button, Input, InputGroup } from "reactstrap";
import { request, getAuthToken } from "../helpers/axios_helper"; // Importer les fonctions de axios_helper.ts
import Header from "../Header/Header";
import { DeleteUser, AddUser, Updateuser } from "../Service/ServicesUtilisateur";

// Définir le type pour les éléments de la liste
// liste modèles
interface ListItem {
    id: number;
    nom: string;
    login: string;
    role: string;
    password: string;
    idClient: string;

}



const AddUserComponent: React.FC = () => (
    <>
        <div className="">
            <AddUser />
        </div>
    </>
);





const FilterComponent: React.FC<{

    filterText: string;
    onFilter: (value: string) => void;
    onClear: () => void;
}> = ({ filterText, onFilter, onClear }) => (
    <>
        <div className="">
            <InputGroup>
                <Input
                    id="search"
                    type="text"
                    placeholder="nom"
                    bsSize="sm"
                    aria-label="Search Input"
                    value={filterText}
                    onChange={(e) => onFilter(e.target.value)}
                />
                <Button color="danger" onClick={onClear} size="sm" outline>
                    X
                </Button>
            </InputGroup>
        </div>
    </>
);

export default function PubEtidClient(): JSX.Element {
    const [filterText, setFilterText] = useState<string>("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [data, setData] = useState<ListItem[]>([]);
    const [id, setId] = useState<number | null>(null);
    const [nom, setNom] = useState<string>("");
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [idclient, setIdclient] = useState<string>("");


    useEffect(() => {
        // Fonction pour effectuer la requête GET avec Axios
        const fetchData = async () => {
            try {
                const token = getAuthToken(); // Récupérer le token du local storage
                const headers = token ? { 'Authorization': `Bearer ${token}` } : {}; // Préparer les headers avec le token (s'il existe)
                const response = await request("GET", "/getListeUser", headers); // Utiliser la fonction request de axios_helper.ts
                setData(response.data.listeUtilisateurs); // Mettre à jour le state avec les données reçues
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);

            }
        };

        fetchData(); // Appeler la fonction fetchData pour récupérer les données lors du chargement du composant
    }, []);

    const handleDelete = (id: any, nom :any) => {
        // Stockez les données de la ligne sélectionnée dans l'état selectedRowData
        setId(id);
        setNom(nom);
    };

    //Style CSS pour data-table-component

    const tableCustomStyles = {
        table: {
            style: {
                width: '100%',
                height: '100%',
            },
        },
        noData: {
            style: {
                color: 'white',
                fontSize: '15px',
                fontWeight: 'bold',
                paddingLeft: '0px',
                justifyContent: 'center',
                backgroundColor: '#FFA500',
                background: '#2D2F35',
            },
        },
        header: {
            style: {
                color: 'white',
                fontSize: '25px',
                fontWeight: 'bold',
                paddingLeft: '0px',
                justifyContent: 'center',
                backgroundColor: '#FFA500',
                background: '#2D2F35',
                width: '100%'
            },
        },
        pagination: {
            style: {
                color: 'white',
                fontSize: '15px',
                fontWeight: 'bold',
                paddingLeft: '0px',
                justifyContent: 'center',
                backgroundColor: '#FFA500',
                background: '#2D2F35'
            },
            pageButtonsStyle: {
                color: '#fff',
            }
        },
        subHeader: {
            style: {
                color: 'white',
                fontSize: '15px',
                fontWeight: 'bold',
                paddingLeft: '0px',
                justifyContent: 'center',
                backgroundColor: '#FFA500',
                background: '#2D2F35'
            },
        },
        headCells: {
            style: {
                color: 'white',
                fontSize: '15px',
                fontWeight: 'bold',
                justifyContent: 'left',
                padding: '-10px',
                Width: '20px',
                backgroundColor: '#FFA500',
                background: 'black',
            },
        },
        rows: {
            style: {
                color: 'white',
                fontSize: '15px',
                paddingLeft: '0px',
                justifyContent: 'center',
                background: '#2D2F35',
            },
        },
    }



    const columns = useMemo(
        () => [
            {
                name: "Noms",
                selector: (row: ListItem) => row.nom,
                sortable: true,
                left: true,
            },
            {
                name: "Logins",
                selector: (row: ListItem) => row.login,
                sortable: true,
                center: true,
            },
            {
                name: "Role",
                selector: (row: ListItem) => row.role,
                sortable: true,
                center: true,
            },
            {
                sortable: true,
                center: true,
            },
            {
                selector: (row: ListItem) => row.id,
                cell: (row: ListItem) => {
                    return (
                        <button className="btn svg" data-bs-target={'#updateuser-' + nom} onClick={() => {
                            setId(row.id);
                            setNom(row.nom);
                            setLogin(row.login);
                            setPassword(row.password);
                            setRole(row.role);
                            setIdclient(row.idClient);
                        }} data-bs-toggle="modal"
                        style={{border:'none', background:'none'}}><img className="btn btn-outline-light" src="/images/edit-icon.svg" alt="ajouter un utilisateur" /></button>
                    )
                },
                allowOverflow: true,
                button: true,
                center: true,
            },
            {
                selector: (row: ListItem) => row.id,
                cell: (row: ListItem) => {
                    return (
                        <button className="btn svg" data-bs-target={'#supprimer'} onClick={()=>{handleDelete(row.id, row.nom)}} data-bs-toggle="modal"
                        style={{border:'none', background:'none'}}><img className="btn btn-outline-light" src="/images/delete.svg" alt="ajouter un utilisateur" /></button>
                    )
                },
                allowOverflow: true,
                button: true,
                center: true,
            },
           


        ],
        []
    );

    const filteredItems = data.filter(
        (item) => item.nom && item.nom.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <div>

                <div className="float-end">
                    <FilterComponent onFilter={setFilterText} onClear={handleClear} filterText={filterText} />
                </div>
            </div>
        );
    }, [filterText, resetPaginationToggle]);

    const subHeadeForUser = useMemo(() => {

        return (
            <div>
                <div className="float-start">
                    <AddUserComponent />
                </div>

            </div>
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <div className="">
            <div className="header-content">
                <Header />
            </div>
            <div className="data-table">
                <DataTable
                    title="Gestion d'utilisateurs"
                    columns={columns}
                    data={filteredItems}
                    highlightOnHover
                    defaultSortFieldId="title"
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader
                    persistTableHead
                    subHeaderComponent={[subHeadeForUser, subHeaderComponentMemo]}
                    customStyles={tableCustomStyles}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[15]}
                    paginationComponentOptions={{ rowsPerPageText: 'Lignes par pages' }}
                    responsive
                />
                <Updateuser
                    id={id}
                    nom={nom}
                    login={login}
                    password={password}
                    role={role}
                    idClient={idclient}
                />
                <DeleteUser
                id={id}
                nom={nom}
                />
            </div>
        </div>
    );
}