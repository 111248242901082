import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Button, Input, InputGroup } from "reactstrap";
import { request, getAuthToken } from "../helpers/axios_helper"; // Importer les fonctions de axios_helper.ts
import Header from "../Header/Header";
import { EditesonAccueil } from "../Service/ServiceAccueil";
import { EditesonInfoEditeur } from "../Service/ServiceInfoEditeur";
import { PropagateLoader } from "react-spinners"; // Importez le spinner que vous souhaitez utiliser
import { useNavigate } from "react-router-dom";
import MultipleNum from "../Service/ServiceGroupeNumeros";

// Définir le type pour les éléments de la liste
// liste modèles
interface ListItem {
  id: string;
  numero: string;
  editeur: string;
  base: string;
  application: string;
  description: string;
  accueil: string;
  pathAccueil: string | null;
  pub: string | null;
  pathPub: string | null;
  infoEditeurs: string;
  pathInfoEditeurs: string | null;
  infos: string | null;
}

interface ListItemCsv {
  [key: string]: string;
  numero: string;
  description: string;
  editeur: string;
  infoEditeurs: string;
  application: string;
  accueil: string;
  pub: string;
}



const FilterComponent: React.FC<{

  filterText: string;
  onFilter: (value: string) => void;
  onClear: () => void;
}> = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="star">
      <InputGroup>
        <Input
          id="search"
          type="text"
          placeholder="Numero"
          bsSize="sm"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => onFilter(e.target.value)}
        />
        <Button color="danger" onClick={onClear} size="sm" outline>
          X
        </Button>
      </InputGroup>
    </div>
  </>
);



function convertArrayOfObjectsToCSV(array: ListItemCsv[]): string {
  let result: string;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array: any[]): void {
  const link = document.createElement('a');

  // Check if the array is undefined or null
  if (!array) {
    console.error("Array is undefined or null");
    return;
  }

  let csv = convertArrayOfObjectsToCSV(array);

  // Check if the CSV is null or undefined
  if (csv == null) {
    console.error("CSV is undefined or null");
    return;
  }

  const filename = 'Fichier_Son.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}


interface ExportProps {
  onExport: () => void;
}

const Exporter: React.FC<ExportProps> = ({ onExport }) => (
  <Button
    className="btn-success"
    style={{ marginRight: '5%' }}
    onClick={onExport}>
    Exporter <img src="./images/icone_csv.svg" alt="Exporter en csv" />
  </Button>
);





export default function PubEtAccueil(): JSX.Element {
  const [filterText, setFilterText] = useState<string>("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
  const [data, setData] = useState<ListItem[]>([]);
  const [dataCsv, setDataCsv] = useState<ListItemCsv[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [base, setbase] = useState("");
  const [numero, setNumero] = useState("");
  const [path, setPath] = useState("");
  const [infos, setInfos] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer l'indicateur de chargement
  const navigate = useNavigate();



  const filteredItems = data.filter(
    (item) => item.numero && item.numero.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    // Fonction pour effectuer la requête GET avec Axios
    const fetchData = async () => {
      setIsLoading(true); // Démarrez l'indicateur de chargement au début de la requête
      try {
        const token = getAuthToken(); // Récupérer le token du local storage
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {}; // Préparer les headers avec le token (s'il existe)
        const response = await request("GET", "/numeroDetail/getliste", headers); // Utiliser la fonction request de axios_helper.ts
        setData(response.data.listeNumeroDetail); // Mettre à jour le state avec les données reçues
        const transformedData: ListItemCsv[] = response.data.listeNumeroDetail.map((item: ListItem) => ({
          numero: item.numero,
          description: item.description,
          editeur: item.editeur,
          infoEditeurs: item.infoEditeurs,
          application: item.application,
          accueil: item.accueil,
          pub: item.pub,
        }));
        setDataCsv(transformedData);
        console.table(transformedData);

      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setIsLoading(false); // Arrêtez l'indicateur de chargement à la fin de la requête
      }
    };

    fetchData(); // Appeler la fonction fetchData pour récupérer les données lors du chargement du composant
  }, []);



  //Style CSS pour data-table-component

  const tableCustomStyles = {
    table: {
      style: {
        width: '100%',
        height: '100%',
      },
    },
    noData: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35',
      },
    },
    header: {
      style: {
        color: 'white',
        fontSize: '25px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35',
        width: '100%'
      },
    },
    pagination: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35'
      },
      pageButtonsStyle: {
        color: '#fff',
      }
    },
    subHeader: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35'
      },
    },
    headCells: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        justifyContent: 'center',
        padding: '-10px',
        Width: '20px',
        backgroundColor: '#FFA500',
        background: 'black',
      },
    },
    rows: {
      style: {
        color: 'white',
        fontSize: '15px',
        paddingLeft: '0px',
        justifyContent: 'center',
        background: '#2D2F35',
      },
    },
  }

  const handleEdit = (base: any, numero: any, path: any) => {
    // Stockez les données de la ligne sélectionnée dans l'état selectedRowData
    setbase(base);
    setNumero(numero);
    setPath(path);
  };
  const handleEditInfoEd = (base: any, numero: any, path: any, infos: any) => {
    // Stockez les données de la ligne sélectionnée dans l'état selectedRowData
    setbase(base);
    setNumero(numero);
    setPath(path);
    setInfos(infos);
  };
  const columns = useMemo(
    () => [
      {
        name: "Numeros",
        selector: (row: ListItem) => row.numero,
        sortable: true,
      },
      {
        name: "Descriptions",
        selector: (row: ListItem) => row.description,
        sortable: true,
        center: true,
      },
      {
        name: "Applications",
        selector: (row: ListItem) => row.application,
        sortable: true,
        center: true,
      },
      {
        name: "Editeurs",
        selector: (row: ListItem) => row.editeur,
        sortable: true,
        center: true,
      },
      {
        name: "Info editeur",
        cell: (row: ListItem) => (
          row.infoEditeurs ? <audio src={"./data/" + row.pathInfoEditeurs + "/" + row.infoEditeurs} controls></audio> : <h1></h1>
        ),
        center: true,
      },
      {
        name: "Editer",
        id: "INFOED",
        selector: (row: ListItem) => row.numero,
        cell: (row: ListItem) => {
          return (
            <button className="btn svg" data-bs-target="#editerSonEditeur" onClick={() => handleEditInfoEd(row.base, row.numero, row.pathInfoEditeurs, row.infos)} data-bs-toggle="modal"><img src="./images/edit-icon.svg" alt="editer son Info Editeur" /></button>
          )
        },
        allowOverflow: true,
        button: true,
        center: true,
      },
      {
        name: "Accueil",
        cell: (row: ListItem) => (
          row.accueil ? <audio src={"./data/" + row.pathAccueil + "/" + row.accueil} controls></audio> : <h1></h1>
        ),
        center: true,
      },
      {
        name: "Editer",
        id: "ACCUEIL",
        cell: (row: ListItem) => {

          return (
            <button className="btn svg" data-bs-target="#editerSonAccueil" onClick={() => handleEdit(row.base, row.numero, row.pathAccueil)} data-bs-toggle="modal"><img src="./images/edit-icon.svg" alt="editer son accueil" /></button>
          )
        },
        allowOverflow: true,
        button: true,
        center: true,
      },
      {
        name: "Pub",
        id: "PUB",
        cell: (row: ListItem) => (
          row.pub == "NON" ? (<h6>NON</h6>) : (<h6>OUI</h6>)
        ),
        center: true,
      },
      {
        name: "Editer",
        id: "ACCUEIL",
        cell: (row: ListItem) => {

          return (
            <button className="btn svg" onClick={() => navigate(`/Pub_aleatoire/${row.base}/${row.numero}`)}><img src="./images/params.svg" alt="editer son accueil" /></button>
          )
        },
        allowOverflow: true,
        button: true,
        center: true,
      },
    ],
    []
  );

  // const filteredItems = data.filter(
  //   (item) => item.numero && item.numero.toLowerCase().includes(filterText.toLowerCase())
  // );


  const actionsMemo = useMemo(() => <Exporter onExport={() => dataCsv && downloadCSV(dataCsv)} />, [dataCsv]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent onFilter={setFilterText} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);


  const handelClickedRows = () => {
    console.log()
  }

  const subHeadeMultipleNum = useMemo(() => {

    return (
      <div>
        <div className="float-start">
          <button className="btn svg" onClick={() => navigate(`/choix_multiple`)}><img src="./images/icone_multiple_choix.svg" alt="choix multiples" /></button>
        </div>

      </div>
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <div className="">
      <div className="header-content">
        <Header />
      </div>
      <div className="data-table">
        {isLoading ? (
          <div className="loading-indicator">
            <PropagateLoader
              color="#C11E65"
              size={15} // Taille de votre spinner
            />
          </div>
        ) : (
          <DataTable
            title="Gestion Son Accueil et Pub"
            columns={columns}
            data={filteredItems}
            highlightOnHover
            defaultSortFieldId="title"
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            persistTableHead
            subHeaderComponent={[subHeadeMultipleNum, subHeaderComponentMemo]}
            customStyles={tableCustomStyles}
            actions={actionsMemo}
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            paginationComponentOptions={{ rowsPerPageText: 'Lignes par pages' }}
            responsive
            onRowClicked={handelClickedRows}
            selectableRowsSingle
            fixedHeader
            fixedHeaderScrollHeight="700px"
          />
        )}

        <EditesonInfoEditeur
          base={base}
          numero={numero}
          path={path}
          infos={infos}
        />
        <EditesonAccueil
          base={base}
          numero={numero}
          path={path}
        />
      </div>
    </div>
  );
}