import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { request, getAuthToken } from "../helpers/axios_helper"; // Importer les fonctions de axios_helper.ts
import Header from "../Header/Header";
import { DeleteSonPub, AddsonPub } from "../Service/ServicePub";
import { PropagateLoader } from "react-spinners"; // Importez le spinner que vous souhaitez utiliser
import axios from "axios";
import { useParams } from "react-router-dom";

// Définir le type pour les éléments de la liste
// liste modèles
interface ListItem {
  numero: string;
  fichier: string;
  base: string;
  path: string | null;
  id:string
}

const PubAleatoire: React.FC = () => {
  // export default function PubAleatoire(props:any): JSX.Element {
  const [filterText, setFilterText] = useState<string>("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
  const [data, setData] = useState<ListItem[]>([]);
  const [base, setbase] = useState('');
  const [id, setId] = useState('');
  const [numero, setNumero] = useState('');
  const [path, setPath] = useState("");
  const [fichier, setFichier] = useState('');
  const [isLoading, setIsLoading] = useState(false); // État pour gérer l'indicateur de chargement
  const { Pnumero, Pbase } = useParams();

  const filteredItems = data.filter(
    (item) => item.numero && item.numero.toLowerCase().includes(filterText.toLowerCase())
  );


  useEffect(() => {
    // Fonction pour effectuer la requête GET avec Axios
    const fetchData = async () => {
      setIsLoading(true); // Démarrez l'indicateur de chargement au début de la requête
      try {
        const token = getAuthToken(); // Récupérer le token du local storage
        const params = {
          numero: Pnumero?.toString()
        }
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {}; // Préparer les headers avec le token (s'il existe)
        const response = await axios.get("/pubDetail/getPub",
          {
            params: params,
            headers: headers
          });


        const limits = response.data.listePubByNumero
        setData(limits)

      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setIsLoading(false); // Arrêtez l'indicateur de chargement à la fin de la requête
      }
    };

    fetchData(); // Appeler la fonction fetchData pour récupérer les données lors du chargement du composant
  }, []);



  //Style CSS pour data-table-component

  const tableCustomStyles = {

    noData: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35',
      },
    },
    header: {
      style: {
        color: 'white',
        fontSize: '25px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35',
        width: '100%'
      },
    },
    pagination: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35'
      },
      pageButtonsStyle: {
        color: '#fff',
      }
    },
    subHeader: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        paddingLeft: '0px',
        justifyContent: 'center',
        backgroundColor: '#FFA500',
        background: '#2D2F35'
      },
    },
    headCells: {
      style: {
        color: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        justifyContent: 'center',
        padding: '-10px',
        Width: '20px',
        backgroundColor: '#FFA500',
        background: 'black',
      },
    },
    rows: {
      style: {
        color: 'white',
        fontSize: '15px',
        paddingLeft: '0px',
        justifyContent: 'center',
        background: '#2D2F35',
      },
    },
  }

  const handleEdit = (base: any, numero: any) => {
    // Stockez les données de la ligne sélectionnée dans l'état selectedRowData
    setbase(base);
    setNumero(numero);
    setPath(path);
  };

  const removeItem = (id:any) => {
    console.log(data.filter(row => row.id != id))
    setData((prev) => prev.filter(row => row.id != id))
  }

  const handledelete = (base: any, numero: any, path: any, fichier:any,id:string) => {
    // Stockez les données de la ligne sélectionnée dans l'état selectedRowData
    setbase(base);
    setNumero(numero);
    setPath(path);
    setFichier(fichier);
    setId(id);
  };
  const columns = useMemo(
    () => [
      {
        name: "Numéro",
        selector: (row: ListItem) => row.numero,
        sortable: true,
        center: true
      },
      {
        name: "Pub",
        id: "PUB",
        cell: (row: ListItem) => row.fichier,
        center: true,
      },
      {
        name: "",
        id: "PUB",
        cell: (row: ListItem) => (
          row.numero ? <audio src={"/data" + row.path + ""+row.fichier}  controls></audio> : <h1></h1>

        ),
        center: true,
      },
      {
        name: "Editer",
        id: "PUB",
        cell: (row: ListItem) => {
          return (
            <button className="btn svg" data-bs-target="#deleteSonPub" onClick={() => handledelete(row.base, row.numero, row.path, row.fichier,row.id)} data-bs-toggle="modal" style={{ border: 'none', padding: '0' }}>
              <img src='/images/delete.svg' alt="editer son Pub" />
            </button>
          )
        },
        allowOverflow: true,
        button: true,
        center: true,
      },
    ],
    []
  );
  const subHeaderPub = useMemo(() => {

    return (
      <div>
        <button type="button" className="btn btn-lg active" data-bs-target="#editerSonPub" onClick={() => handleEdit(Pbase, Pnumero)} data-bs-toggle="modal" style={{ border: 'none', padding: '0' }}>              
          <img src='/images/add-sound.svg' alt="editer son Pub" />
        </button>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="">
      <div className="header-content">
        <Header />
      </div>
      <div className="container-fluid">

        {isLoading ? (
          <div className="loading-indicator">
            <PropagateLoader
              color="#C11E65"
              size={15} // Taille de votre spinner
            />
          </div>
        ) : (
          <DataTable
            title="Gestion de son pub"
            columns={columns}
            data={filteredItems}
            highlightOnHover
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            persistTableHead
            subHeaderComponent={[subHeaderPub]}
            customStyles={tableCustomStyles}
            responsive
            selectableRowsSingle
          />
        )}
        <DeleteSonPub
          base={base}
          numero={numero}
          path={path}
          pub={fichier}
          id={id}
          remove={(t:any) => removeItem(t)}
        />
        <AddsonPub
          base={base}
          numero={numero}
          path={path}
        />
      </div>
    </div>
  );
}

export default PubAleatoire